import { NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { COLORS } from '@shared/ui/sdk/utils/constants';

import AuthContainer from '../../components/AuthContainer';
import AuthForm from '../../components/AuthForm';
import AuthTextInput from '../../components/AuthTextInput';
import FormFooter from '../../components/Login/FormFooter';
import PrivacyAndTermsOfService from '../../components/PrivacyAndTermsOfService';
import { login } from '../../store/operations/auth';
import { State, useDispatch, useSelector } from '../../store/types';
import { getImpersonateParam } from '../../utils';
import { afterLoginRedirect, setTokenCookie, withAuth } from '../../utils/auth';
import config from '../../utils/config';
import { DEFAULT_ERROR_MESSAGE, INACTIVE_USER_ERROR_MESSAGE } from '../../utils/constants';
import { Context } from '../_app';

type Props = {};

export const FooterRoot = styled.div`
  position: absolute;
  bottom: 14px;
  text-align: center;
`;

export const FormContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const FormSublabel = styled.span`
  margin-top: 20px;
  font-weight: 500;
  text-align: center;
  color: ${COLORS.GREY};
  font-size: 13px;
`;

const Login: NextPage<Props> = () => {
  const router = useRouter();

  const dispatch = useDispatch();
  const auth = useSelector((state: State) => state.auth);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await dispatch(login(email, password, getImpersonateParam(router.asPath as string)));
  }

  useEffect(() => {
    if (auth.accessToken) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setTokenCookie(auth.accessToken, rememberMe);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      afterLoginRedirect();
    }
  }, [auth.accessToken]);

  useEffect(() => {
    if (router.query.inactive) {
      alert(INACTIVE_USER_ERROR_MESSAGE);
    }
  }, []);

  useEffect(() => {
    if (config.COOKIE_CONSENT_SDK_ID && !window.Cookiebot) {
      alert(
        'Please accept the cookie consent to proceed. It looks like you have a cookie blocker enabled. Please whitelist Paragon and reload your page.',
      );
    } else if (
      config.COOKIE_CONSENT_SDK_ID &&
      window.Cookiebot &&
      window.Cookiebot.consent.stamp === '0'
    ) {
      alert('Please accept the cookie consent to proceed.');
      window.Cookiebot.show();
    }
  }, []);

  const emailInput = (
    <AuthTextInput
      disabled={auth.loginLoading}
      type="email"
      label="Email address"
      value={email}
      onChange={setEmail}
      placeholder="Email address"
      data-testid="login-email"
    />
  );

  const passwordInput = (
    <AuthTextInput
      disabled={auth.loginLoading}
      type="password"
      label="Password"
      value={password}
      onChange={setPassword}
      placeholder="Password"
      data-testid="login-password"
    />
  );

  const formFooter = (
    <FormFooter
      disabled={auth.loginLoading}
      rememberMe={rememberMe}
      onRememberMeChange={setRememberMe}
    />
  );

  const submitLabel = auth.loginLoading ? 'Logging In…' : 'Login';
  const error = auth.loginError ? auth.loginErrorMessage || DEFAULT_ERROR_MESSAGE : undefined;
  const form = (
    <AuthForm
      title="Welcome back!"
      subtitle="Login to your Paragon account below."
      submitLabel={submitLabel}
      loading={auth.loginLoading}
      error={error}
      onSubmit={handleSubmit}
    >
      {emailInput}
      {passwordInput}
      {formFooter}
    </AuthForm>
  );

  const signupPrompt = (
    <FormSublabel>
      New to Paragon?&nbsp;
      <Link href="/signup">
        <a>Create an account</a>
      </Link>
    </FormSublabel>
  );

  const footer = (
    <FooterRoot>
      <PrivacyAndTermsOfService />
    </FooterRoot>
  );

  return (
    <AuthContainer hideSplitPaneView>
      <FormContainer>
        {form}
        {signupPrompt}
        {footer}
      </FormContainer>
    </AuthContainer>
  );
};

Login.getInitialProps = async (_ctx: Context) => ({});

export default withAuth(true, Login);
