import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@shared/ui/sdk/utils/constants';

import { PRIVACY_AND_POLICY_LINK, TERMS_OF_SERVICE_LINK } from '../utils/constants';

const PrivacyAndTermsOfServiceRoot = styled.div`
  font-weight: 500;
  font-size: 11px;
  color: ${COLORS.GREY};
  text-align: center;
  margin-bottom: 14px;
`;

const LinkRoot = styled.a`
  color: ${COLORS.GREY};
`;

const PrivacyAndTermsOfService = () => {
  return (
    <PrivacyAndTermsOfServiceRoot>
      By signing up, you agree to our{' '}
      <LinkRoot href={TERMS_OF_SERVICE_LINK}>Terms of Service</LinkRoot> and{' '}
      <LinkRoot href={PRIVACY_AND_POLICY_LINK}>Privacy Policy</LinkRoot>
    </PrivacyAndTermsOfServiceRoot>
  );
};

export default PrivacyAndTermsOfService;
