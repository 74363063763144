import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@shared/ui/sdk/utils/constants';

type Props = {
  disabled: boolean;
  rememberMe: boolean;
  onRememberMeChange: (checked: boolean) => void;
};

const FormFooterRoot = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

const FormFooterLink = styled.a`
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
`;

const FormFooterRememberMeLabel = styled.span`
  font-weight: 500;
  color: ${COLORS.GREY};
  margin-left: 5px;
  font-size: 12px;
`;

const FormFooter = (props: Props) => {
  function handleRememberMeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    props.onRememberMeChange(event.target.checked);
  }

  return (
    <FormFooterRoot>
      <label>
        <input
          disabled={props.disabled}
          type="checkbox"
          value="rememberMe"
          checked={props.rememberMe}
          aria-checked={props.rememberMe}
          onChange={handleRememberMeChange}
        />
        <FormFooterRememberMeLabel>Remember me</FormFooterRememberMeLabel>
      </label>
      <Link href="/ForgotPassword" as="/forgot-password">
        <FormFooterLink>Forgot password?</FormFooterLink>
      </Link>
    </FormFooterRoot>
  );
};

export default FormFooter;
